import React, { useEffect } from 'react';
import { Radio, Form } from 'antd';
import { map, get, some, filter } from 'lodash';

import { useFormatMessage } from '../hooks'

const { Item } = Form;

const sizeTranslationsTable = {
  "3x3": 'Box.Size.Keys',
  "11x4": 'Box.Size.Small',
  "12x4": 'Box.Size.Small',
  "11x10": 'Box.Size.Mid',
  "12x10": 'Box.Size.Mid',
  "11x16": 'Box.Size.Big',
  "12x16": 'Box.Size.Big',
  "11x34": 'Box.Size.Big',
  "12x34": 'Box.Size.Big',
  "11x69": 'Box.Size.Super',
  "12x69": 'Box.Size.Super',
  "null-size": 'Box.Size.Unknown',
}

export const sizeTranslations = (apexSize) => {
  if (!apexSize) return "Box.Size.Unknown"
  return sizeTranslationsTable[apexSize] ?? "Box.Size.Unknown"
}

const lockerStyle = {
  alignItems: 'center',
  display: 'flex',
  height: 72,
  justifyContent: 'center',
  lineHeight: '210%',
  maxWidth: 100,
  minWidth: 65,
};

const BoxSize = ({ lockers, name = 'boxSize', showButton = true }) => {
  const f = useFormatMessage()
  const form = Form.useFormInstance()
  const btnTxt = f('CreateDelivery.BoxSizeSelectAt1') + ' ' + f('CreateDelivery.BoxSizeSelectAt2');
  const viewableLockers = filter(lockers ?? [], (l) => (l?.size !== null && l.size !== 'null-size'))

  useEffect(() => {
    if (!form || !lockers) return
    const currentBoxSize = form.getFieldValue("boxSize")

    const viewableLockers = filter(lockers, (l) => (l?.size !== null && l.size !== 'null-size'))
    if (!lockers.find(locker => locker.size === currentBoxSize)) {
      form.setFieldValue("boxSize", get(viewableLockers, '[0].size'))
      form.setFieldValue("oldBoxSize", get(viewableLockers, '[0].size'))
    }
  }, [lockers, form])

  if (!some(viewableLockers, l => l.lockerType === "Apex")) return null

  return (
    <Item
      name={name}
      label={f('CreateDelivery.BoxSize')}
      rules={[{ required: true, message: 'Please select box size!' }]}
    >
      <Radio.Group
        size="large"
        className="lockers"
        buttonStyle="solid"
        style={{ display: 'flex', marginBottom: 16, textAlign: 'center' }}
      >
        {map(viewableLockers, (item, i) => (
          <Radio.Button
            value={item.size}
            key={i}
            style={{ ...lockerStyle, minWidth: lockerStyle.minWidth + i * 10, marginRight: 20 }}
          >
            <div>{f(sizeTranslations(item.size))}</div>
            <div className="locker-size" style={{ fontSize: 'x-small', lineHeight: '0px' }}>{item.sizeText}</div>
            <div>{item.numAvailable}</div>
          </Radio.Button>
        ))}
        {showButton && (
          <Radio.Button
            value={'none'}
            key={viewableLockers.length}
            style={{ ...lockerStyle, lineHeight: 'normal', marginLeft: 'auto' }}
          >
            {btnTxt}
          </Radio.Button>
        )}
      </Radio.Group>
    </Item>
  )
}

export default BoxSize
